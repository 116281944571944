import Cookies from 'js-cookie'

const TokenKey = 'token'

import CryptoJS from 'crypto-js'

// 密钥
var key = '9p368NONwyJtHesy'
// 密钥偏移量
var iv = '9238567840125512'

export function decrypt(ciphertext) {
  var decrypt = CryptoJS.AES.decrypt(ciphertext, CryptoJS.enc.Utf8.parse(key), {
    iv: CryptoJS.enc.Utf8.parse(iv),
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  })
  return decrypt.toString(CryptoJS.enc.Utf8)
}

export function getToken() {
  // return Cookies.get(TokenKey)
  return localStorage.getItem(TokenKey)
}

export function setToken(token) {
  localStorage.setItem(TokenKey, token)
  // return Cookies.set(TokenKey, token, { domain: process.env.VUE_APP_ROOT })
}

export function removeToken() {
  return localStorage.removeItem(TokenKey)
  // return Cookies.remove(TokenKey, { domain: process.env.VUE_APP_ROOT })
}

export function getUserInfo() {
  return Cookies.get('user')
}

export function setUserInfo(data) {
  return Cookies.set('user', data)
}
// 金额千分位
export function toThousands(num) {
  if (!num) {
    return '0.00'
  }
  if (!/^(\+|-)?(\d+)(\.\d+)?$/.test(num)) {
    return num
  }
  var a = RegExp.$1; var b = RegExp.$2; var c = RegExp.$3
  var re = new RegExp().compile('(\\d)(\\d{3})(,|$)')
  while (re.test(b)) {
    b = b.replace(re, '$1,$2$3')
  }
  return a + '' + b + '' + c
}
export const Resize = {
  mounted(el, binding) {
    const handler = binding.value
    const options = {
      passive: !binding.modifiers?.active
    }

    window.addEventListener('resize', handler, options)

    el._onResize = {
      handler,
      options
    }

    if (!binding.modifiers?.quiet) {
      handler()
    }
  },

  unmounted(el) {
    if (!el._onResize) return

    const { handler, options } = el._onResize
    window.removeEventListener('resize', handler, options)
    delete el._onResize
  }
}

export default Resize
