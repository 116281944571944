<template>
  <div class="nav_box">
    <a-breadcrumb>
      <a-breadcrumb-item>
        <router-link to="/">管理后台</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item v-if="route.matched[0].meta.title">
        <router-link :to="route.matched[0].path">{{ route.matched[0].meta.title }}</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item v-if="route.matched[1].meta.title">{{ route.matched[1].meta.title }}</a-breadcrumb-item>
    </a-breadcrumb>
  </div>
</template>
<script setup>
import { useRoute } from 'vue-router'
const route = useRoute()

</script>
<style lang="less" scoped>
.nav_box {
  width: 100%;
  height: 55px;
  padding: 0 20px;
  background-color: #fff;
  display: flex;
  align-items: center;
}
</style>