import { createRouter, createWebHashHistory } from 'vue-router'
import Layouts from '@/layouts/index'

const routes = [
  {
    path: '/',
    component: Layouts,
    meta: { title: '工作台' },
    redirect: '/datastatistics/home-page',
    children: [
      {
        path: '/datastatistics/home-page',
        meta: { title: '工作台' },
        component: () => import('@/views/datastatistics/index')
      },
      {
        path: '404',
        name: '404',
        meta: { title: '404' },
        component: () => import('@/views/error/404')
      }
    ]
  },
  // {
  //   path: '/404',
  //   name: '404',
  //   component: Layouts,
  //   component: () => import('@/views/error/404'),
  //   meta: { title: '404' }
  // },
  
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/sign/login'),
    meta: { title: '登录' }
  }
]
export const asyncRoutes = [
  {
    path: '/storeApplication',
    component: Layouts,
    meta: { title: '店铺管理' },
    auth: 'store',
    redirect: '/storeApplication/shop-list',
    children: [
      {
        path: '/storeApplication/shop-list',
        meta: { title: '店铺列表' },
        component: () => import('@/views/shoplist/storelist'),
        auth: 'store_list'
      },
      {
        path: '/shopdetails/:id',
        meta: { title: '店铺详情', activeMenu: '/storeApplication/shop-list' },
        component: () => import('@/views/shoplist/storeappde/storedetails'),
        auth: 'store_detail'
      },
      {
        path: '/storeApplication/apply-list',
        meta: { title: '店铺申请' },
        component: () => import('@/views/shoplist/storeapp'),
        auth: 'store_apply_list'
      },
      {
        path: '/storedetails/:id',
        meta: { title: '店铺申请详情', activeMenu: '/storeApplication/apply-list' },
        component: () => import('@/views/shoplist/storeappde/shopdetails'),
        auth: 'store_apply_detail'
      },
      {
        path: '/storereview/:id',
        meta: { title: '店铺申请审核', activeMenu: '/storeApplication/apply-list' },
        component: () => import('@/views/shoplist/storeappde/StoreReview'),
        auth: 'store_apply_set_audit_status'
      },
      {
        path: '/storeApplication/store-change',
        meta: { title: '主体变更' },
        component: () => import('@/views/shoplist/storechange'),
        auth: 'store_main_audit_list'
      },
      {
        path: '/storechangeexam/:id/:iden',
        meta: { title: '主体变更审核', activeMenu: '/storeApplication/store-change' },
        component: () => import('@/views/shoplist/storechange/storeoper'),
        auth: 'store_main_audit_set_audit_status'
      },
      {
        path: '/storechangdeta/:id',
        meta: { title: '主体变更详情', activeMenu: '/storeApplication/store-change' },
        component: () => import('@/views/shoplist/storechange/storeoper'),
        auth: 'store_main_audit_detail'
      },
      {
        path: '/storeApplication/subject-change',
        meta: { title: '店铺变更' },
        component: () => import('@/views/shoplist/subjectchange'),
        auth: 'store_audit_list'
      },
      {
        path: '/subjectchangeexam/:id/:iden',
        meta: { title: '店铺变更审核', activeMenu: '/storeApplication/subject-change' },
        component: () => import('@/views/shoplist/subjectchange/storeoper'),
        auth: 'store_audit_set_audit_status'
      },
      {
        path: '/subjectchangdeta/:id',
        meta: { title: '店铺详情', activeMenu: '/storeApplication/subject-change' },
        component: () => import('@/views/shoplist/subjectchange/storeoper'),
        auth: 'store_audit_detail'
      }
    ]
  },
  {
    path: '/categorysettings',
    meta: { title: '类目设置' },
    auth: 'category',
    redirect: '/categorysettings/equip-category',
    component: Layouts,
    children: [
      {
        path: '/categorysettings/equip-category',
        meta: { title: '设备类目' },
        component: () => import('@/views/category/equiplist'),
        auth: 'equip_category_list'
      },
      {
        path: '/addequip',
        meta: { title: '新增分类' , activeMenu: '/categorysettings/equip-category' },
        component: () => import('@/views/category/equip/addequip'),
        auth: 'equip_category_add'
      },
      {
        path: '/addequip/:id',
        meta: { title: '编辑分类', activeMenu: '/categorysettings/equip-category'  },
        component: () => import('@/views/category/equip/addequip'),
        auth: 'equip_category_edit'
      },
      {
        path: '/addequip/:id/:iden',
        meta: { title: '复制分类', activeMenu: '/categorysettings/equip-category'},
        component: () => import('@/views/category/equip/addequip'),
        auth: 'equip_category_copy_add'
      },
      {
        path: '/categorysettings/access-category',
        meta: { title: '配件类目' },
        component: () => import('@/views/category/accesslist'),
        auth: 'goods_category_list'
      },
      {
        path: '/addgoods',
        meta: { title: '新增分类', activeMenu: '/categorysettings/access-category' },
        component: () => import('@/views/category/equip/access'),
        auth: 'goods_category_add'
      },
      {
        path: '/addgoods/:id',
        meta: { title: '编辑分类', activeMenu: '/categorysettings/access-category' },
        component: () => import('@/views/category/equip/access'),
        auth: 'goods_category_edit'
      },
      {
        path: '/addgoods/:id/:iden',
        meta: { title: '复制分类', activeMenu: '/categorysettings/access-category'},
        component: () => import('@/views/category/equip/access'),
        auth: 'goods_category_copy_add'
      },
      {
        path: '/categorysettings/labor-category',
        meta: { title: '劳务类目' },
        component: () => import('@/views/category/laborlist'),
        auth: 'home_job_category_list'
      },
      {
        path: '/addlabor',
        meta: { title: '新增分类', activeMenu: '/categorysettings/labor-category' },
        component: () => import('@/views/category/equip/labor'),
        auth: 'home_job_category_add'
      },
      {
        path: '/addlabor/:id',
        meta: { title: '编辑分类', activeMenu: '/categorysettings/labor-category' },
        component: () => import('@/views/category/equip/labor'),
        auth: 'home_job_category_edit'
      },
      {
        path: '/categorysettings/attri-manage',
        meta: { title: '属性管理' },
        component: () => import('@/views/category/attrilist'),
        auth: 'attr_list'
      },
      {
        path: '/attriequip',
        meta: { title: '新增属性', activeMenu: '/categorysettings/attri-manage'  },
        component: () => import('@/views/category/equip/attri'),
        auth: 'attr_add'
      },
      {
        path: '/attriequip/:id',
        meta: { title: '编辑分类', activeMenu: '/categorysettings/attri-manage'  },
        component: () => import('@/views/category/equip/attri'),
        auth: 'attr_edit'
      }
    ]
  },
  {
    path: '/jurisdiction',
    meta: { title: '系统管理' },
    icon:'/setting',
    component: Layouts,
    auth: 'setting_system_admin',
    redirect: '/jurisdiction/admin-list',
    children: [
      {
        path: '/jurisdiction/admin-list',
        meta: { title: '管理员列表' },
        component: () => import('@/views/jurisdiction/admin-list'),
        auth: 'system_admin_list'
      },
      {
        path: '/jurisdiction/role-list',
        meta: { title: '角色管理' },
        component: () => import('@/views/jurisdiction/role-list'),
        auth: 'setting_system_role_list'
      },
      {
        path: '/jurisdiction/helpdocu-list',
        meta: { title: '帮助文档' },
        component: () => import('@/views/jurisdiction/helpdocu-list'),
        auth: 'article_list'
      },
      {
        path: '/addhelpdocu',
        meta: { title: '新增文档', activeMenu: '/jurisdiction/helpdocu-list' },
        component: () => import('@/views/jurisdiction/addhelpdocu'),
        auth: 'article_add'
      },
      {
        path: '/edithelpdocu/:id',
        meta: { title: '编辑文档', activeMenu: '/jurisdiction/helpdocu-list' },
        component: () => import('@/views/jurisdiction/addhelpdocu'),
        auth: 'article_edit'
      },
      {
        path: '/jurisdiction/banner-set',
        meta: { title: 'Banner设置' },
        component: () => import('@/views/jurisdiction/bannerset'),
        auth: 'banner_list'
      },
      {
        path: '/jurisdiction/terrace',
        meta: { title: '平台设置' },
        component: () => import('@/views/jurisdiction/terrace'),
        auth: 'system_config_watermark_info'
      }
    ]
  },
  { 
    path: '/information',
    meta: { title: '信息管理' },
    auth: 'home_job',
    icon:'/setting',
    component: Layouts,
    redirect: '/information/bidrent-list',
    children: [,
      {
        path: '/information/bidrent-list',
        name: 'BidrentList',
        meta: { title: '竞价求租' },
        component: () => import('@/views/information/bidrentlist'),
        auth: 'bidding_list'
      },
      {
        path: '/bidrentExam/:id',
        meta: { title: '竞价求租审核', activeMenu: '/information/bidrent-list' },
        component: () => import('@/views/information/toexamine/bidrent'),
        auth: 'bidding_set_status'
      },
      {
        path: '/bidrentExam/:id/:iden',
        meta: { title: '竞价求租详情', activeMenu: '/information/bidrent-list' },
        component: () => import('@/views/information/toexamine/bidrent'),
        auth: 'bidding_detail'
      },
      {
        path: '/competitive/:id',
        meta: { title: '竞价报价详情' , activeMenu: '/information/bidrent-list'},
        component: () => import('@/views/information/toexamine/competitive'),
        auth: 'bidding_detail'
      },
      {
        path: '/information/equipment-list',
        meta: { title: '设备需求' },
        component: () => import('@/views/information/equipmentlist'),
        auth: 'home_purchase_equip_list'
      },
      {
        path: '/equipment/:id',
        meta: { title: '设备求租信息审核' , activeMenu: '/information/equipment-list' },
        component: () => import('@/views/information/toexamine/equipment'),
        auth: 'home_purchase_equip_set'
      },
      {
        path: '/equipment/:id/:iden',
        meta: { title: '设备求租信息详情' , activeMenu: '/information/equipment-list' },
        component: () => import('@/views/information/toexamine/equipment'),
        auth: 'home_purchase_equip_detail'
      },
      {
        path: '/equiplease/:id',
        meta: { title: '设备出租信息审核' , activeMenu: '/information/equipment-list' },
        component: () => import('@/views/information/toexamine/equipment'),
        auth: 'home_purchase_equip_set'
      },
      {
        path: '/equiplease/:id/:iden',
        meta: { title: '设备出租信息详情' , activeMenu: '/information/equipment-list' },
        component: () => import('@/views/information/toexamine/equipment'),
        auth: 'home_purchase_equip_detail'
      },
      {
        path: '/equipwant/:id',
        meta: { title: '二手机求购信息审核' , activeMenu: '/information/equipment-list' },
        component: () => import('@/views/information/toexamine/equipment'),
        auth: 'home_purchase_equip_set'
      },
      {
        path: '/equipwant/:id/:iden',
        meta: { title: '二手机求购信息详情' , activeMenu: '/information/equipment-list' },
        component: () => import('@/views/information/toexamine/equipment'),
        auth: 'home_purchase_equip_detail'
      },
      {
        path: '/equipsell/:id',
        meta: { title: '二手机出售信息审核' , activeMenu: '/information/equipment-list' },
        component: () => import('@/views/information/toexamine/equipment'),
        auth: 'home_purchase_equip_set'
      },
      {
        path: '/equipsell/:id/:iden',
        meta: { title: '二手机出售信息详情' , activeMenu: '/information/equipment-list' },
        component: () => import('@/views/information/toexamine/equipment'),
        auth: 'home_purchase_equip_detail'
      },
      {
        path: '/equipNew/:id',
        meta: { title: '新机求购信息审核' , activeMenu: '/information/equipment-list' },
        component: () => import('@/views/information/toexamine/equipment'),
        auth: 'home_purchase_equip_set'
      },
      {
        path: '/equipNew/:id/:iden',
        meta: { title: '新机求购信息详情' , activeMenu: '/information/equipment-list' },
        component: () => import('@/views/information/toexamine/equipment'),
        auth: 'home_purchase_equip_detail'
      },
      {
        path: '/information/laodemand-list',
        meta: { title: '劳务需求' },
        component: () => import('@/views/information/laodemandlist'),
        auth: 'home_job_list'
      },
      {
        path: '/laodrecruit/:id',
        meta: { title: '机手招聘信息审核', activeMenu: '/information/laodemand-list' },
        component: () => import('@/views/information/toexamine/laodemand'),
        auth: 'home_job_set'
      },
      {
        path: '/laodrecruit/:id/:iden',
        meta: { title: '机手招聘信息详情', activeMenu: '/information/laodemand-list' },
        component: () => import('@/views/information/toexamine/laodemand'),
        auth: 'home_job_detail'
      },
      {
        path: '/laodjob/:id',
        meta: { title: '求职简历信息审核', activeMenu: '/information/laodemand-list' },
        component: () => import('@/views/information/toexamine/laodemand'),
        auth: 'home_job_set'
      },
      {
        path: '/laodjob/:id/:iden',
        meta: { title: '求职简历信息详情' , activeMenu: '/information/laodemand-list'},
        component: () => import('@/views/information/toexamine/laodemand'),
        auth: 'home_job_detail'
      },
      {
        path: '/information/parts-list',
        meta: { title: '配件需求' },
        component: () => import('@/views/information/partslist'),
        auth: 'home_purchase_parts_list'
      },
      {
        path: '/laodbuy/:id',
        meta: { title: '配件求购信息审核', activeMenu: '/information/parts-list' },
        component: () => import('@/views/information/toexamine/wantbuy'),
        auth: 'home_purchase_parts_set'
      },
      {
        path: '/laodbuy/:id/:iden',
        meta: { title: '配件求购信息详情', activeMenu: '/information/parts-list' },
        component: () => import('@/views/information/toexamine/wantbuy'),
        auth: 'home_purchase_parts_detail'
      }
    ]
  },
  {
    path: '/commodity',
    auth: 'goods_equip',
    meta: { title: '商品管理' },
    component: Layouts,
    redirect: '/commodity/rental-list',
    children: [
      {
        path: 'rental-list',
        name: 'RentalList',
        meta: { title: '租赁设备' },
        component: () => import('@/views/commodity/rentallist'),
        auth: 'equip_list1'
      },
      {
        path: '/leaseDeta/:id',
        meta: { title: '商品审核', activeMenu: '/commodity/rental-list'  },
        component: () => import('@/views/commodity/operation/rental'),
        auth: 'equip_set_status1'
      },
      {
        path: '/leaseExam/:id/:iden',
        meta: { title: '商品详情', activeMenu: '/commodity/rental-list' },
        component: () => import('@/views/commodity/operation/rental'),
        auth: 'equip_detail1'
      },
      {
        path: '/commodity/mobile-list',
        meta: { title: '二手机' },
        component: () => import('@/views/commodity/mobilelist'),
        auth: 'equip_list3'
      },
      {
        path: '/mobileDeta/:id',
        meta: { title: '商品审核', activeMenu: '/commodity/mobile-list'  },
        component: () => import('@/views/commodity/operation/mobile'),
        auth: 'equip_set_status3'
      },
      {
        path: '/mobileExam/:id/:iden',
        meta: { title: '商品详情', activeMenu: '/commodity/mobile-list' },
        component: () => import('@/views/commodity/operation/mobile'),
        auth: 'equip_detail3'
      },
      {
        path: '/commodity/newmachine-list',
        meta: { title: '新机' },
        component: () => import('@/views/commodity/newmachinelist'),
        auth: 'equip_list4'
      },
      {
        path: '/newmachineDeta/:id',
        meta: { title: '商品审核', activeMenu: '/commodity/newmachine-list'  },
        component: () => import('@/views/commodity/operation/newmachine'),
        auth: 'equip_set_status4'
      },
      {
        path: '/newmachineExam/:id/:iden',
        meta: { title: '商品详情', activeMenu: '/commodity/newmachine-list' },
        component: () => import('@/views/commodity/operation/newmachine'),
        auth: 'equip_detail4'
      },
      {
        path: '/commodity/parts-list',
        meta: { title: '配件' },
        component: () => import('@/views/commodity/partslist'),
        auth: 'goods_list'
      },
      {
        path: '/partsDeta/:id',
        meta: { title: '商品审核', activeMenu: '/commodity/parts-list'  },
        component: () => import('@/views/commodity/operation/parts'),
        auth: 'goods_set_status'
      },
      {
        path: '/partsExam/:id/:iden',
        meta: { title: '商品详情', activeMenu: '/commodity/parts-list' },
        component: () => import('@/views/commodity/operation/parts'),
        auth: 'goods_detail'
      }
    ]
  },
  {
    path: '/announinfo',
    meta: { title: '公告与资讯' },
    auth: 'message',
    icon:'/setting',
    component: Layouts,
    redirect: '/announinfo/announ-list',
    children: [
      {
        path: '/announinfo/announ-list',
        meta: { title: '公告管理' },
        component: () => import('@/views/announinfo/announlist'),
        auth: 'message_list1'
      },
      {
        path: '/add_notice',
        meta: { title: '新增公告', activeMenu: '/announinfo/announ-list'  },
        component: () => import('@/views/announinfo/operation/announlist'),
        auth: 'message_add1'
      },
      {
        path: '/edit_notice/:id',
        meta: { title: '编辑公告', activeMenu: '/announinfo/announ-list'  },
        component: () => import('@/views/announinfo/operation/announlist'),
        auth: 'message_edit1'
      },
      {
        path: '/see_notice/:id/:iden',
        meta: { title: '查看公告', activeMenu: '/announinfo/announ-list'  },
        component: () => import('@/views/announinfo/operation/announlist'),
        auth: 'message_detail1'
      },
      {
        path: '/announinfo/infor-list',
        meta: { title: '资讯管理' },
        component: () => import('@/views/announinfo/inforlist'),
        auth: 'message_list2'
      },
      {
        path: '/add_real_time',
        meta: { title: '新增资讯' , activeMenu: '/announinfo/infor-list' },
        component: () => import('@/views/announinfo/operation/inforlist'),
        auth: 'message_add2'
      },
      {
        path: '/edit_real_time/:id',
        meta: { title: '编辑资讯', activeMenu: '/announinfo/infor-list'  },
        component: () => import('@/views/announinfo/operation/inforlist'),
        auth: 'message_edit2'
      },
      {
        path: '/see_real_time/:id/:iden',
        meta: { title: '查看资讯', activeMenu: '/announinfo/infor-list'  },
        component: () => import('@/views/announinfo/operation/inforlist'),
        auth: 'message_detail2'
      },
      {
        path: '/announinfo/Label-list',
        meta: { title: '标签管理' },
        component: () => import('@/views/announinfo/Labellist'),
        auth: 'message_tag_list'
      }
    ]
  },
  {
    path: '/usermanagement',
    meta: { title: '用户管理' },
    auth: 'user',
    icon:'/setting',
    component: Layouts,
    redirect: '/usermanagement/member-users',
    children: [
      {
        path: '/usermanagement/member-users',
        meta: { title: '会员用户' },
        component: () => import('@/views/usermanagement/memberUsers'),
        auth: 'home_user_list'
      },
      {
        path: '/usermanagement/member-realname',
        meta: { title: '会员实名' },
        component: () => import('@/views/usermanagement/memberRealname'),
        auth: 'home_user_apply_list'
      },
      {
        path: '/realname-exam/:id',
        meta: { title: '会员实名审核', activeMenu: '/usermanagement/member-realname' },
        component: () => import('@/views/usermanagement/operation/realname'),
        auth: 'home_user_apply_set_audit_stauts'
      },
      {
        path: '/realname-deta/:id/:iden',
        meta: { title: '会员实名详情', activeMenu: '/usermanagement/member-realname'},
        component: () => import('@/views/usermanagement/operation/realname'),
        auth: 'home_user_apply_detail'
      },
      {
        path: '/usermanagement/merchant-users',
        meta: { title: '商家用户' },
        component: () => import('@/views/usermanagement/merchantUsers'),
        auth: 'store_user_list'
      }
    ]
  },
  {
    path: '/userfeedback',
    meta: { title: '用户反馈' },
    icon:'/setting',
    auth: 'feedback',
    component: Layouts,
    redirect: '/userfeedback/message-management',
    children: [
      {
        path: '/userfeedback/message-management',
        meta: { title: '留言管理' },
        component: () => import('@/views/userfeedback/message'),
        auth: 'feedback_msg_list'
      },
      {
        path: '/userfeedback/report-management',
        meta: { title: '举报处理' },
        component: () => import('@/views/userfeedback/report'),
        auth: 'feedback_report_list'
      }
    ]
  },
  { path: '/:pathMatch(.*)', meta: { title: '404' }, redirect: '/404', auth: 'constant' }
]
const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
