import { createApp } from 'vue'
import Antd from 'ant-design-vue'
import App from './App'
import 'ant-design-vue/dist/antd.less'
import router from './router'
import './style/index.less'
import './permission'
import v3ImgPreview from 'v3-img-preview'
import { UserOutlined, InfoCircleOutlined } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import directives from './utils/directives'
import { Boot } from '@wangeditor/editor'
import attachmentModule from '@wangeditor/plugin-upload-attachment'
Boot.registerModule(attachmentModule)

const app = createApp(App).use(router)


app.use(directives)
app.component('QuillEditor', QuillEditor)


// 引入pinia
import { createPinia } from 'pinia'
const pinia = createPinia()
app.use(pinia)
app.use(v3ImgPreview)

import { mainStore } from './store'
const store = mainStore()

app.directive('auth', {
  mounted(el, binding) {
    const { value } = binding
    if (value) {
      const hasPermission = localStorage.getItem('app:auth').includes(value)
      if (!hasPermission) {
        el.parentNode && el.parentNode.removeChild(el)
      }
    } else {
      throw new Error('error!')
    }
  }
})

app.use(Antd).mount('#app')