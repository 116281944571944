
import { defineStore } from 'pinia'

export const mainStore = defineStore('main', {
  state: () => {
    return {
      collapsed: false, // 侧边栏是否收起
      auth: [],
      userInfo: {},
      menus: []
    }
  },
  getters: {

  },
  actions: {
  }
})