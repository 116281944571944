import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import router from '@/router'
import { getToken } from '@/utils/auth'
import 'nprogress/nprogress.css'
import { asyncRoutes } from '@/router'
import { mainStore } from '@/store'

const whiteList = ['Login']

let delay = false
router.beforeEach((to, from, next) => {
  // debugger
  NProgress.start()
  const hasToken = getToken()
  if (hasToken) {
    authFilter() // 路由权限筛选
    if (to.path === '/login') {
      next('/')
    } else {
      if (delay) {
        next()
      } else {
        delay = true
        next({ ...to, replace: true })
      }
    }
  } else {
    if (whiteList.indexOf(to.name) !== -1) {
      next()
    } else {
      next({ path: '/login' })
    }
  }
})
// 路由权限筛选
function authFilter() {
  const store = mainStore()
  const routes = []
  asyncRoutes.map(item => {
    if (localStorage.getItem('app:auth').includes(item.auth) || item.auth === 'constant') {
      if (!item.children) {
        routes.push(item)
      } else {
        const child = []
        item.children.map((v, k) => {
          if (localStorage.getItem('app:auth').includes(v.auth)) {
            child.push(v)
          }
        })
        item.children = child
        if(item.redirect) { // 如果路由设置了重定向，则默认重定向到第一个子菜单
          item.redirect = child[0]
        }
        routes.push(item)
      }
    }
  })
  routes.map(item => {
    router.addRoute(item)
  })
}
router.afterEach(to => {
  NProgress.done()
  document.title = to.meta.title
})