<template>
  <div class="layouts_header">
    <router-link to="/" class="flex align-center pointer">
      <img class="layouts_logo" src="/images/logo.png">
      <div class="layouts_line" />
      <span>管理后台</span>
    </router-link>
    <div class="icon_navber">
      <div>
        <arrow-left-outlined @click="clickBackoff" />
      </div>
      <div>
        <redo-outlined @click="clickRefresh" />
      </div>
      <div>
        <img class="img_icon" src="@/style/images/profile-user.png">
      </div>
      <div>
        <a-dropdown>
          <a class="ant-dropdown-link" @click.prevent>
            {{ username }}
            <DownOutlined />
          </a>
          <template #overlay>
            <a-menu>
              <a-menu-item>
                <a href="javascript:;" @click="changepwd">修改密码</a>
              </a-menu-item>
              <a-menu-item>
                <a href="javascript:;" @click="loginOut">退出登录</a>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </div>
    </div>
  </div>
  <div class="layouts">
    <div class="left" :style="`width:${store.collapsed?50:210}px`">
      <div class="lay_sidebar" style="padding-bottom:40px">
        <sidebar />
      </div>
      <div class="sidebar_btn" :style="`width:${store.collapsed?50:210}px`">
        <img src="/images/MenuFold.png" @click="collapsedChange">
      </div>
    </div>
    <div class="right">
      <navbar />
      <div class="content_box">
        <div class="contents">
          <!-- <a-spin :spinning="loading" size="large"> -->
          <router-view />
          <!-- </a-spin> -->
        </div>
      </div>
    </div>
  </div>
  <a-modal v-model:visible="pwdvisible" title="修改密码">
    <div>
      <a-form ref="formRef" :model="formState">
        <a-form-item label="原密码" :rules="[{ required: true, message: '请输入内容' }]" name="pwd">
          <a-input v-model:value="formState.pwd" placeholder="请输入" />
        </a-form-item>
        <a-form-item label="新密码" :rules="[{ required: true, message: '请输入内容' }]" name="new_pwd">
          <a-input v-model:value="formState.new_pwd" placeholder="请输入" />
        </a-form-item>
        <a-form-item label="确认新密码" :rules="[{ required: true, message: '请输入内容' }]" name="conf_pwd">
          <a-input v-model:value="formState.conf_pwd" placeholder="请输入" />
        </a-form-item>
      </a-form>
    </div>
    <template #footer>
      <a-button key="back" @click="pwdvisible = false">取消</a-button>
      <a-button key="submit" type="primary" @click="closepwd">提交</a-button>
    </template>
  </a-modal>
</template>
<script setup>
import sidebar from './sidebar.vue'
import navbar from './navbar.vue'
import { useRouter } from 'vue-router'
import { mainStore } from '@/store/index'
import { reactive, ref, watch,createVNode, defineComponent } from 'vue'
import { removeToken } from '@/utils/auth'
import { DownOutlined, ArrowLeftOutlined, RedoOutlined,ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { updateAdmin } from '@/api/home'
import { message,Modal  } from 'ant-design-vue'
const store = mainStore()
const router = useRouter()
const username = ref(JSON.parse(localStorage.getItem('app:user_info')).account)
const props = defineProps({
  visible: {
    type: Boolean,
    default: true
  }
})
const pwdvisible = ref(false)
const loading = reactive(true)
const formState = ref({})
const formRef = ref()
watch(() => props.visible, () => {
  loading.value = props.visible
})
function collapsedChange() {
  store.collapsed = !store.collapsed
}
function loginOut() {
  removeToken()
  for (const key in window.localStorage) {
    if (key.startsWith('app:')) {
      localStorage.removeItem(key)
    }
  }
  router.go(0)
}
function clickRefresh() {
  router.go(0)
}
function clickBackoff() {
  window.history.go(-1)
}
function changepwd() {
  pwdvisible.value = true
}
const closepwd = async () => {
  try {
    const values = await formRef.value.validateFields()
    if(formState.value.new_pwd !== formState.value.conf_pwd){
      message.error('两次输入的密码不相同')
      return
    }
    const pares = {
      ...formState.value
    }
    updateAdmin(pares).then(res => {
      Modal.confirm({
        title: '密码已修改 需要重新登陆',
        icon: createVNode(ExclamationCircleOutlined),
        onOk() {
          removeToken()
          for (const key in window.localStorage) {
            if (key.startsWith('app:')) {
              localStorage.removeItem(key)
            }
          }
          router.go(0)
        }
      })
    })
  } catch (errorInfo) {
    console.log('Failed:', errorInfo)
  }
}
</script>
<style lang="less" scoped>
:deep(.ant-form label){
  width: 90px !important;
}
.img_icon{
  width: 25px;
  height: 25px;

}
.icon_navber{
  display:flex ;
  align-items: center;
}
.icon_navber div{
 margin-right: 10px;
}
.content_box {
  max-height: calc(100% - 55px);
  overflow: auto;
}
.ant-dropdown-link{
  color: #fff;
  font-weight: 400;
  margin-right: 20px;
}
.layouts_header {
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  background-color: #00356e;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  .layouts_logo {
    margin-left: 25px;
    height: 33px;
  }
  .layouts_line {
    width: 2px;
    height: 20px;
    background-color: #fff;
    margin: 0 10px;
  }
  span {
    font-size: 18px;
    color: #fff;
  }
}
.layouts {
  display: flex;
  height: 100%;
  padding-top: 45px;
  position: relative;
  .left {
    height: 100%;
    background-color: #fff;
    overflow: auto;
    position: relative;
    .sidebar_btn {
      height: 40px;
      position: fixed;
      bottom: 0;
      box-shadow: inset 0px 1px 0px 0px #F0F0F0;
      display: flex;
      align-items: center;
      padding-left: 15px;
      background-color: #fff;
      img {
        width: 16px;
        height: 16px;
        cursor: pointer;
      }
    }
  }
  .left::-webkit-scrollbar {
    width: 6px;
    // background-color: #fff;
  }
  .left::-webkit-scrollbar-thumb {
    background-color: rgba(144, 147, 153, 0.3);
    border-radius: 8px;
  }

  // .left:hover::-webkit-scrollbar-thumb {
  //   width: 6px;
  //   background: rgba(144, 147, 153, 0.3);
  //   border-radius: 8px;
  // }
  .left:hover::-webkit-scrollbar{
    width: 6px;
  }
  .right {
    flex: 1;
    width: 10px;
      background-color: #F6F7F9;
    .contents {
      // margin: 10px;
      background-color: #F6F7F9;
      // padding: 20px;
    }
  }
}
</style>