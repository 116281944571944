import request from '@/utils/request'

// export function getStoreCity(params) {
//   return request({
//     url: '/store/city',
//     method: 'get',
//     params
//   })
// }
// export function homeUpload(data) {
//   return request({
//     url: '/store/pic',
//     method: 'post',
//     data
//   })
// }
// export function getStoreCaptcha(params) {
//   return request({
//     url: '/store/captcha',
//     method: 'get',
//     params
//   })
// }
// export function getSmsCode(data) {
//   return request({
//     url: '/store/sms',
//     method: 'post',
//     data
//   })
// }
// export function storeUserCheckPhone(params) {
//   return request({
//     url: '/store/user/check_phone',
//     method: 'get',
//     params
//   })
// }
// export function storeUserCheckSms(data) {
//   return request({
//     url: '/store/user/check_sms',
//     method: 'post',
//     data
//   })
// }
// export function storeApplySave(data) {
//   return request({
//     url: '/store/apply/save',
//     method: 'post',
//     data
//   })
// }


// // home
// export function homeLogin(data) {
//   return request({
//     url: 'http://yapi.smart-xwork.cn/mock/190216/login',
//     method: 'post',
//     data
//   })
// }
// 修改密码
export function updateAdmin(data) {
  return request({
    url: '/admin/system/admin/updateAdmin',
    method: 'post',
    data
  })
}

export function login(data) {
  return request({
    url: '/admin/login',
    method: 'post',
    data
  })
}
// 图片验证码
export function captcha(params) {
  return request({
    url: '/admin/captcha',
    method: 'get',
    params
  })
}
// 店铺申请列表
export function storeApplyList(params) {
  return request({
    url: '/admin/store/apply/list',
    method: 'get',
    params
  })
}
// 店铺类型列表
export function applyStoreTypeLis(params) {
  return request({
    url: '/admin/store/apply/storeTypeList',
    method: 'get',
    params
  })
}
// 审核状态列表
export function applyAuditStatusList(params) {
  return request({
    url: '/admin/store/apply/auditStatusList',
    method: 'get',
    params
  })
}
// 商家类型列表
export function applyStoreMainTypeList(params) {
  return request({
    url: '/admin/store/apply/storeMainTypeList',
    method: 'get',
    params
  })
}
// 店铺申请详情
export function applyDetail(id) {
  return request({
    url: '/admin/store/apply/detail/' + id,
    method: 'get'
  })
}
// 店铺申请审核详情
export function applyEditDetail(id) {
  return request({
    url: '/admin/store/apply/editDetail/' + id,
    method: 'get'
  })
}
// 店铺申请审核
export function applySetAuditStatus(data) {
  return request({
    url: '/admin/store/apply/setAuditStatus',
    method: 'post',
    data
  })
}
// 用户留言
export function feedbackMsg(params) {
  return request({
    url: '/admin/feedback/msg/list',
    method: 'get',
    params
  })
}
// 留言状态列表
export function msgStatusList(params) {
  return request({
    url: '/admin/feedback/msg/statusList',
    method: 'get',
    params
  })
}
// 删除/恢复
export function msgSetStatus(data) {
  return request({
    url: '/admin/feedback/msg/setStatus',
    method: 'post',
    data
  })
}
// 编辑留言
export function feedbackMsgEdit(data) {
  return request({
    url: '/admin/feedback/msg/edit',
    method: 'post',
    data
  })
}
// 工作台
export function indexData(params) {
  return request({
    url: '/admin/index/data',
    method: 'get',
    params
  })
}
// 举报列表
export function reportList(params) {
  return request({
    url: '/admin/feedback/report/list',
    method: 'get',
    params
  })
}
// 举报状态列表
export function reportStatusList(params) {
  return request({
    url: '/admin/feedback/report/statusList',
    method: 'get',
    params
  })
}
// 举报类型列表
export function reportTypeList(params) {
  return request({
    url: '/admin/feedback/report/typeList',
    method: 'get',
    params
  })
}
// 举报处理
export function feedbackReportSet(data) {
  return request({
    url: '/admin/feedback/report/set',
    method: 'post',
    data
  })
}
// 举报历史列表
export function reportHistoryList(params) {
  return request({
    url: '/admin/feedback/report/historyList',
    method: 'get',
    params
  })
}

// 省市
export function citySecond(params) {
  return request({
    url: '/admin/citySecond',
    method: 'get',
    params
  })
}
